<template lang='pug'>
	.driving-conditions
		.header
			.content.constrainer
				img.header-image(src='@/assets/jpg/interestingplaces.jpg')
				h1.title Driving conditions.
				.dashes
					.dash(v-for='n in 5')
				.para.fs Be aware that the weather in Iceland is ever changing and can be dangerous to those unfamiliar to weather conditions, even in the summer.
		.body-section.gutter.constrainer
			//.check-my-route
			//	h1.title Check my route.
			//	.dashes
			//		.dash(v-for='n in 5')
			//	.para.fs Before you head out check if there are any issues that you need to be aware of by adding your destinations and point of arrival to the route checker
			//.searcher
			//	vue-google-autocomplete#origin(ref='origin' classname='form-control' placeholder='Enter your location' v-on:placechanged='getOriginData' :country='["is"]' types='geocode')
			//	vue-google-autocomplete#destination(ref='destination' classname='form-control' placeholder='Enter your destination' v-on:placechanged='getDestinationData' :country='["is"]' types='geocode')
			//	.info(@click='showBox = !showBox' v-click-outside='closeBox')
			//		.text i
			//		.info-box(:class='{ active: showBox }') safe.is does not guarantee that the data is accurate. The data is fetched from other sources and the driving conditions can change quickly, even while you are driving. By using the route checker you agree and acknowledge that Safe.is is in no way responsible for any accidents or other issues that might happen to you or your belongings at your trip.
			//.results
			//	.loader(v-if='loadingRoute')
			//		.left
			//		.text loading
			//		.right
			//	.date(v-if='searched') {{ now }}
			//	.info.dashed(v-for='data, index in displayData')
			//		.top
			//			.basics
			//				.location
			//					.circle
			//					.text {{ data.location }}
			//				.condition
			//					.pad
			//					.text {{ data.condition }}
			//			.see-on-map.desktop(:class='{ open: data.openDrop }')
			//				.link-text(@click='data.openDrop = !data.openDrop') Show on map <span> <img src='@/assets/icons/caret_blue.svg'> </span>
			//				.links
			//					a.url(:href='url', v-for='url, index in data.mapsUrls') view location {{ index + 1 }}
			//		.bottom
			//			.distance
			//				.pad
			//				img.icon(src='@/assets/icons/location.svg')
			//				.text {{ getDistance(data.coordinates[0][1], data.coordinates[0][0], data.lat, data.lon) }}
			//			.temp(v-if='data.weather')
			//				.pad
			//				img.icon(src='@/assets/icons/temp.svg')
			//				.text {{ Math.round(data.weather.main.temp * 10) / 10 }}°C / {{ Math.round(((data.weather.main.temp * 1.8) + 32) * 10) / 10 }}°F
			//			.wind
			//				.pad
			//				img.icon(:src='`/directions/${data.windCode}.svg`')
			//				.text {{ data.windCode }} {{ data.weather.wind.speed }} m/s
			//			.see-on-map.mobile(:class='{ open: data.openDrop }')
			//				.link-text(@click='data.openDrop = !data.openDrop') Show on map <span> <img src='@/assets/icons/caret_blue.svg'> </span>
			//				.links
			//					a.url(:href='url', target='_blank', v-for='url, index in data.mapsUrls') view location {{ index + 1 }}
			//	.info.coast-clear(v-if='!displayData.length && searched')
			//		.text The coast is clear
			//	.info.show-route(v-if='searched')
			//		a(:href='this.mapsUrl' target='_blank') Show route on map
			//- .section-pad
			//- 	BookBanner(
			//- 		:bannerNumber="7"
			//- 	)
			.bluecarrental-banner
				BlueCarRentalBanner(
					:image="{ url: require('@/assets/bluecarrental/image4.jpg') }"
					text="We recommend using Blue Car Rental for booking safe and reliable cars in Iceland. Driving around our beautiful island relies heavily on your rental car."
				)
			.weather-and-conditions
				.top
					.left
						h1.title Road conditions and weather.
						.dashes
							.dash(v-for='n in 5')
						.para.fs Select the part of the country that you want to display.
					.right
						.map.desktop-mini
							.img-cont-top(@click='selectArea(6)')
								img.one(src='@/assets/map/conditions/1b.svg')
								img.one(src='@/assets/map/conditions/1a.svg' :class='{ active: areaSelection === "Northwest" || areaSelection === "Entire Iceland" }')
							.img-cont-top(@click='selectArea(8)')
								img.two(src='@/assets/map/conditions/2b.svg')
								img.two(src='@/assets/map/conditions/2a.svg' :class='{ active: areaSelection === "North" || areaSelection === "Entire Iceland" }')
							.img-cont-top(@click='selectArea(7)')
								img.three(src='@/assets/map/conditions/3b.svg')
								img.three(src='@/assets/map/conditions/3a.svg' :class='{ active: areaSelection === "Northeast" || areaSelection === "Entire Iceland" }')
							.img-cont-mid(@click='selectArea(5)')
								img.four(src='@/assets/map/conditions/4b.svg')
								img.four(src='@/assets/map/conditions/4a.svg' :class='{ active: areaSelection === "West" || areaSelection === "Entire Iceland" }')
							.img-cont-mid(@click='selectArea(0)')
								img.five(src='@/assets/map/conditions/5b.svg')
								img.five(src='@/assets/map/conditions/5a.svg' :class='{ active: areaSelection === "Entire Iceland" }')
							.img-cont-mid(@click='selectArea(1)')
								img.six(src='@/assets/map/conditions/6b.svg')
								img.six(src='@/assets/map/conditions/6a.svg' :class='{ active: areaSelection === "East" || areaSelection === "Entire Iceland" }')
							.img-cont-bot(@click='selectArea(4)')
								img.seven(src='@/assets/map/conditions/7b.svg')
								img.seven(src='@/assets/map/conditions/7a.svg' :class='{ active: areaSelection === "Southwest" || areaSelection === "Entire Iceland" }')
							.img-cont-bot(@click='selectArea(3)')
								img.eight(src='@/assets/map/conditions/8b.svg')
								img.eight(src='@/assets/map/conditions/8a.svg' :class='{ active: areaSelection === "South" || areaSelection === "Entire Iceland" }')
							.img-cont-bot(@click='selectArea(2)')
								img.nine(src='@/assets/map/conditions/9b.svg')
								img.nine(src='@/assets/map/conditions/9a.svg' :class='{ active: areaSelection === "Southeast" || areaSelection === "Entire Iceland" }')
						.drop(v-click-outside='closeDrop' :class='{ active: showDrop }')
							.heading.clickable(@click='showDrop = !showDrop')
								.text {{ areaSelection }}
								img.caret(src='@/assets/icons/caret_black.svg')
							.content(:class='{ active: showDrop }')
								.item.clickable(v-for='item, index in areas' @click='selectArea(index)' :class='{ selected: item.selected }')
									.text(:class='{ selected: item.title === areaSelection }') {{ item.title }}

				.bottom
					img(src='https://www.vegagerdin.is/vgdata/faerd/faerdarkort/vestfirdir.png' v-show='areaSelection === "Northwest"')
					img(src='https://www.vegagerdin.is/vgdata/faerd/faerdarkort/nordurland.png' v-show='areaSelection === "North"')
					img(src='https://www.vegagerdin.is/vgdata/faerd/faerdarkort/nordausturland.png' v-show='areaSelection === "Northeast"')
					img(src='https://www.vegagerdin.is/vgdata/faerd/faerdarkort/austurland.png' v-show='areaSelection === "East"')
					img(src='https://www.vegagerdin.is/vgdata/faerd/faerdarkort/sudausturland.png' v-show='areaSelection === "Southeast"')
					img(src='https://www.vegagerdin.is/vgdata/faerd/faerdarkort/sudurland.png' v-show='areaSelection === "South"')
					img(src='https://www.vegagerdin.is/vgdata/faerd/faerdarkort/sudvesturland.png' v-show='areaSelection === "Southwest"')
					img(src='https://www.vegagerdin.is/vgdata/faerd/faerdarkort/vesturland.png' v-show='areaSelection === "West"')
					img(src='https://www.vegagerdin.is/vgdata/faerd/faerdarkort/island.png' v-show='areaSelection === "Entire Iceland"')
					.explanation
						.exp
							img(src='@/assets/mapicons/easily_passable.svg')
							.text Easily passable
						.exp
							img(src='@/assets/mapicons/spots_of_ice.svg')
							.text Spots of Ice
						.exp
							img(src='@/assets/mapicons/slippery.svg')
							.text Slippery
						.exp
							img(src='@/assets/mapicons/extremely_slippery.svg')
							.text Extremely slippery
						.exp
							img(src='@/assets/mapicons/wet_snow.svg')
							.text Wet snow/snow
						.exp
							img(src='@/assets/mapicons/difficult_driving.svg')
							.text Difficult driving
						.exp
							img(src='@/assets/mapicons/difficult_condition.svg')
							.text Difficult condition
						.exp
							img(src='@/assets/mapicons/impassable.svg')
							.text Impassable/Closed
						.exp
							img(src='@/assets/mapicons/no_winter_service.svg')
							.text No winter service
						.exp
							img(src='@/assets/mapicons/unknown_condition.svg')
							.text Unknown condition
						.exp
							img(src='@/assets/mapicons/snow_showers.svg')
							.text Snow showers
						.exp
							img(src='@/assets/mapicons/snowfall.svg')
							.text Snowfall
						.exp
							img(src='@/assets/mapicons/blowing_snow.svg')
							.text Blowing snow
						.exp
							img(src='@/assets/mapicons/blizzard.svg')
							.text Blizzard
						.exp
							img(src='@/assets/mapicons/wet_storm_conditions.svg')
							.text Wet Storm conditions
						.exp
							img(src='@/assets/mapicons/fog.svg')
							.text Fog
						.exp
							img(src='@/assets/mapicons/blowing_sand.svg')
							.text Blowing Sand
						.exp
							img(src='@/assets/mapicons/unknown.svg')
							.text Unknown
						.exp
							img(src='@/assets/mapicons/winter_service.svg')
							.text Winter service
						.exp
							img(src='@/assets/mapicons/mountain_vehicles.svg')
							.text Mountain vehicles
						.exp
							img(src='@/assets/mapicons/closed.svg')
							.text Closed
						.exp
							img(src='@/assets/mapicons/driving_prohibited.svg')
							.text Driving prohibited
			//- AD Driving conditions one
			//- .space-driving-one.main-desktop
			//- 	.adspace
			//- 		Adspace(:key='$store.state.keydex + 6' :id='645')
			//-.space-driving-one.main-mobile
				.adspace
					Adspace(:key='$store.state.keydex + 600' :id='671')
			//- .bluecarrental-banner
			//- 	BlueCarRentalBanner(
			//- 		:image="{ url: require('@/assets/bluecarrental/image4.jpg') }"
			//- 		text="We recommend using Blue Car Rental for booking safe and reliable cars in Iceland. Driving around our beautiful island relies heavily on your rental car."
			//- 	)
			.conditions-by-month
				h1.title Conditions by month.
				.dashes
					.dash(v-for='n in 5')
				.para.fs Click the month you will be travelling in Iceland to see how conditions may affect your driving experience and plans.
				.month-grid
					.month.clickable(v-for='month, index in months' :class='{ active: month.active }' @click='selectMonth(index)') {{ month.name }}
			.selected-month
				.left
					img.big-pic(src='@/assets/jpg/winter.jpg' v-if='selectedMonth === 0')
					img.big-pic(src='@/assets/jpg/winter.jpg' v-if='selectedMonth === 1')
					img.big-pic(src='@/assets/jpg/spring.jpg' v-if='selectedMonth === 2')

					img.big-pic(src='@/assets/jpg/spring.jpg' v-if='selectedMonth === 3')
					img.big-pic(src='@/assets/jpg/spring.jpg' v-if='selectedMonth === 4')
					img.big-pic(src='@/assets/jpg/summer.jpg' v-if='selectedMonth === 5')

					img.big-pic(src='@/assets/jpg/summer.jpg' v-if='selectedMonth === 6')
					img.big-pic(src='@/assets/jpg/summer.jpg' v-if='selectedMonth === 7')
					img.big-pic(src='@/assets/jpg/fall.jpg' v-if='selectedMonth === 8')

					img.big-pic(src='@/assets/jpg/fall.jpg' v-if='selectedMonth === 9')
					img.big-pic(src='@/assets/jpg/fall.jpg' v-if='selectedMonth === 10')
					img.big-pic(src='@/assets/jpg/winter.jpg' v-if='selectedMonth === 11')
				.right
					.mini-title {{ months[selectedMonth].title }}
					.point-section
						.point(v-for='point in months[selectedMonth].points')
							.left
								img(src='@/assets/icons/blue_dots.svg')
							.right {{ point }}
			.up-to-date
				h1.title Where can I find the most up to date information?
				.dashes
					.dash(v-for='n in 5')
				.para.fs The weather in Iceland can be unpredictable. Check the forecast at least once a day and stay up to date for storm warnings. A harsh storm can be dangerous for those trapped on the road.
				.points
					.point
						.left
							img(src='@/assets/icons/blue_dots.svg')
						.right If you find yourself in a dangerous situation, call 112 for assistance.
					.point
						.left
							img(src='@/assets/icons/blue_dots.svg')
						.right Check out Road.is or call 1777 for information about road conditions before travelling.
					.point
						.left
							img(src='@/assets/icons/blue_dots.svg')
						.right Visit Safetravel.is to log your travel plan with the authorities.
			//- .space-driving-two.bottom.main-desktop
			//- 	.adspace
			//- 		Adspace(:key='$store.state.keydex + 7' :id='667')
			//-.space-driving-two.bottom.main-mobile
				.adspace
					Adspace(:key='$store.state.keydex + 700' :id='681')
			.section-pad.margin-bottom
				BlueCarRentalBanner(
					:image="{ url: require('@/assets/bluecarrental/image1.jpg') }"
					text="Safe.is recommends Blue Car Rental for booking and renting safe, reliable cars with the best service possible. Driving around our beautiful island relies heavily on your rental car."
					text-align="left"
				)
			FAQSection
			//- .bluecarrental-banner
			//- 	BlueCarRentalBanner(
			//- 		:image="{ url: require('@/assets/bluecarrental/image1.jpg') }"
			//- 		text-align="left"
			//- 	)
</template>

<script>
import ClickOutside from 'vue-click-outside';
import VueGoogleAutocomplete from 'vue-google-autocomplete';
export default {
	name: 'DrivingConditions',
	metaInfo: {
		title: 'Safe.is - Driving conditions.',
		meta: [
			{
				name: 'og:site_name',
				property: 'og:site_name',
				content: 'Safe.is'
			},
			{
				name: 'og:url',
				property: 'og:url',
				content: 'https://safe.is/driving-conditions/index.html'
			},
			{
				name: 'og:image',
				property: 'og:image',
				content: 'https://safe.is/interesting/roadconditions.jpg'
			},
			{
				name: 'og:title',
				property: 'og:title',
				content: 'Safe.is - Driving conditions.'
			},
			{
				name: 'og:description',
				property: 'og:description',
				content: 'Before driving in Iceland, make sure to check the road and weather conditions on your route for safe driving in Iceland.'
			},
			{
				name: 'description',
				property: 'description',
				content: 'Before driving in Iceland, make sure to check the road and weather conditions on your route for safe driving in Iceland.'
			}
		]
	},
	directives: {
		ClickOutside
	},
	components: {
		VueGoogleAutocomplete
	},
	data: function () {
		return {
			loadingRoute: false,
			showBox: false,
			showDrop: false,
			areas: [
				{
					title: 'Entire Iceland',
					selected: false
				},
				{
					title: 'East',
					selected: false
				},
				{
					title: 'Southeast',
					selected: false
				},
				{
					title: 'South',
					selected: true
				},
				{
					title: 'Southwest',
					selected: false
				},
				{
					title: 'West',
					selected: false
				},
				{
					title: 'Northwest',
					selected: false
				},
				{
					title: 'Northeast',
					selected: false
				},
				{
					title: 'North',
					selected: false
				}
			],
			now: '',
			searched: false,
			temp: {},
			origin: '',
			conditions: {},
			destination: '',
			displayData: [],
			mapsUrl: 'https://www.google.com/maps/dir/',
			windCodes: ['N', 'NNE', 'NE', 'ENE', 'E', 'ESE', 'SE', 'SSE', 'S', 'SSW', 'SW', 'WSW', 'W', 'WNW', 'NW', 'NNW'],
			windDirections: [
				{ min: 348.75, max: 11.25 },
				{ min: 11.25, max: 33.75 },
				{ min: 33.75, max: 56.25 },
				{ min: 56.25, max: 78.75 },
				{ min: 78.75, max: 101.25 },
				{ min: 101.25, max: 123.75 },
				{ min: 123.75, max: 146.25 },
				{ min: 146.25, max: 168.75 },
				{ min: 168.75, max: 191.25 },
				{ min: 191.25, max: 213.75 },
				{ min: 213.75, max: 236.25 },
				{ min: 236.25, max: 258.75 },
				{ min: 258.75, max: 281.25 },
				{ min: 281.25, max: 303.75 },
				{ min: 303.75, max: 348.75 }
			],
			selectedMonth: 0,
			months: [
				{
					name: 'Jan.',
					active: true,
					title: 'January',
					img: '/interesting/placeholder.jpg',
					points: [
						'In winter, the days are short, and the night is dark, plan your travelling time in accordance.',
						'In snowy conditions, keep your distance from other cars, you want plenty of space to react if something goes wrong. ',
						'Roads in Iceland are often very icy in wintertime, even if you don´t see it.',
						'If the road is icy, slow down your approaches, do not make turns unless the car is no longer accelerating and turn slowly.',
						'Drive according to conditions, driving in darkness in unfamiliar snowy locations is no place for maximum allowed speed.',
						'Look out for winds that exceed 15 m/s as mountainous areas could produce very strong wind gusts'
					]
				},
				{
					name: 'Feb.',
					active: false,
					title: 'February',
					img: '/interesting/placeholder.jpg',
					points: [
						'In winter, the days are short, and the night is dark, plan your travelling time in accordance.',
						'In snowy conditions, keep your distance from other cars, you want plenty of space to react if something goes wrong.',
						'Roads in Iceland are often very icy in wintertime, even if you don´t see it.',
						'If the road is icy, slow down your approaches, do not make turns unless the car is no longer accelerating and turn slowly.',
						'Drive according to conditions, driving in darkness in unfamiliar snowy locations is no place for maximum allowed speed.',
						'Look out for winds that exceed 15 m/s as mountainous areas could produce very strong wind gusts.'
					]
				},
				{
					name: 'Mar.',
					active: false,
					title: 'March',
					img: '/interesting/placeholder.jpg',
					points: [
						'In snowy conditions, keep your distance from other cars, you want plenty of space to react if something goes wrong.',
						'Roads in Iceland are often very icy in wintertime, even if you don´t see it.',
						'If the road is icy, slow down your approaches, do not make turns unless the car is no longer accelerating and turn slowly.',
						'Drive according to conditions, driving in darkness in unfamiliar snowy locations is no place for maximum allowed speed.',
						'Look out for winds that exceed 15 m/s as mountainous areas could produce very strong wind gusts'
					]
				},
				{
					name: 'Apr.',
					active: false,
					title: 'April',
					img: '/interesting/placeholder.jpg',
					points: [
						'In snowy conditions, keep your distance from other cars, you want plenty of space to react if something goes wrong.',
						'Roads in Iceland are often very icy in wintertime, even if you don´t see it.',
						'If the road is icy, slow down your approaches, do not make turns unless the car is no longer accelerating and turn slowly.',
						'Drive according to conditions, driving in darkness in unfamiliar snowy locations is no place for maximum allowed speed.',
						'Look out for winds that exceed 15 m/s as mountainous areas could produce very strong wind gusts'
					]
				},
				{
					name: 'May',
					active: false,
					title: 'May',
					img: '/interesting/placeholder.jpg',
					points: [
						'During the summer the sun is always shining, do not lose track of time! Rest if you are feeling tired, no matter how high the sun is in the sky.'
					]
				},
				{
					name: 'Jun.',
					active: false,
					title: 'June',
					img: '/interesting/placeholder.jpg',
					points: [
						'During the summer the sun is always shining, do not lose track of time! Rest if you are feeling tired, no matter how high the sun is in the sky.',
						'Beware of sheep crossing the road. Icelandic sheep roam somewhat freely during the summer months and sometimes they will run across the road. Be aware of them if you see them running and slow down.'
					]
				},
				{
					name: 'Jul.',
					active: false,
					title: 'July',
					img: '/interesting/placeholder.jpg',
					points: [
						'During the summer the sun is always shining, do not lose track of time! Rest if you are feeling tired, no matter how high the sun is in the sky.',
						'Beware of sheep crossing the road. Icelandic sheep roam somewhat freely during the summer months and sometimes they will run across the road. Be aware of them if you see them running and slow down.'
					]
				},
				{
					name: 'Aug.',
					active: false,
					title: 'August',
					img: '/interesting/placeholder.jpg',
					points: [
						'During the summer the sun is always shining, do not lose track of time! Rest if you are feeling tired, no matter how high the sun is in the sky.',
						'Beware of sheep crossing the road. Icelandic sheep roam somewhat freely during the summer months and sometimes they will run across the road. Be aware of them if you see them running and slow down.'
					]
				},
				{
					name: 'Sep.',
					active: false,
					title: 'September',
					img: '/interesting/placeholder.jpg',
					points: [
						'In snowy conditions, keep your distance from other cars, you want plenty of space to react if something goes wrong.',
						'Roads in Iceland are often very icy in wintertime, even if you don´t see it.',
						'If the road is icy, slow down your approaches, do not make turns unless the car is no longer accelerating and turn slowly.',
						'Drive according to conditions, driving in darkness in unfamiliar snowy locations is no place for maximum allowed speed.',
						'Look out for winds that exceed 15 m/s as mountainous areas could produce very strong wind gusts',
						'Beware of sheep crossing the road. Icelandic sheep roam somewhat freely during the summer and autumn months and sometimes they will run across the road. Be aware of them if you see them running and slow down.'
					]
				},
				{
					name: 'Oct.',
					active: false,
					title: 'October',
					img: '/interesting/placeholder.jpg',
					points: [
						'In winter, the days are short and the night is dark, plan your travelling time in accordance.',
						'Keep your distance from other cars, you want plenty of space to react if something goes wrong.',
						'Slow down your approaches, do not make turns unless the car is no longer accelerating and turn slowly',
						'Roads in Iceland are often very icy in wintertime, even if you don´t see it.',
						'Drive according to conditions, driving in darkness in unfamiliar snowy locations is no place for maximum allowed speed.',
						'Look out for winds that exceed 15 m/s as mountainous areas could produce very strong wind gusts'
					]
				},
				{
					name: 'Nov.',
					active: false,
					title: 'November',
					img: '/interesting/placeholder.jpg',
					points: [
						'In winter, the days are short, and the night is dark, plan your travelling time in accordance.',
						'In snowy conditions, keep your distance from other cars, you want plenty of space to react if something goes wrong.',
						'Roads in Iceland are often very icy in wintertime, even if you don´t see it.',
						'If the road is icy, slow down your approaches, do not make turns unless the car is no longer accelerating and turn slowly.',
						'Drive according to conditions, driving in darkness in unfamiliar snowy locations is no place for maximum allowed speed.',
						'Look out for winds that exceed 15 m/s as mountainous areas could produce very strong wind gusts'
					]
				},
				{
					name: 'Dec.',
					active: false,
					title: 'December',
					img: '/interesting/placeholder.jpg',
					points: [
						'In winter, the days are short, and the night is dark, plan your travelling time in accordance.',
						'In snowy conditions, keep your distance from other cars, you want plenty of space to react if something goes wrong.',
						'Roads in Iceland are often very icy in wintertime, even if you don´t see it.',
						'If the road is icy, slow down your approaches, do not make turns unless the car is no longer accelerating and turn slowly.',
						'Drive according to conditions, driving in darkness in unfamiliar snowy locations is no place for maximum allowed speed.',
						'Look out for winds that exceed 15 m/s as mountainous areas could produce very strong wind gusts'
					]
				}
			]
		};
	},
	computed: {
		areaSelection: function () {
			let area = 'Entire Iceland';
			for (let i = 0; i < this.areas.length; i++) {
				if (this.areas[i].selected) {
					area = this.areas[i].title;
					break;
				}
			}
			return area;
		}
	},
	mounted: function () {
		this.$store.state.keydex += 1;
	},
	created: function () {
		this.axios.get('https://us-central1-safe-3ee55.cloudfunctions.net/conditions').then((result) => {
			this.conditions = result.data;
			this.now = this.$moment().format('Do MMM. ‘YY');
		});
	},
	methods: {
		closeBox: function () {
			this.showBox = false;
		},
		closeDrop: function () {
			this.showDrop = false;
		},
		selectArea: function (index) {
			for (let i = 0; i < this.areas.length; i++) {
				if (i === index) {
					this.areas[i].selected = true;
				} else {
					this.areas[i].selected = false;
				}
			}
			this.showDrop = false;
		},
		selectMonth: function (index) {
			for (let i = 0; i < this.months.length; i++) {
				if (index === i) {
					this.months[i].active = true;
				} else {
					this.months[i].active = false;
				}
			}
			this.selectedMonth = index;
		},
		toRadians: function (loc) {
			return loc * Math.PI / 180;
		},
		getDistance: function (lat1, lon1, lat2, lon2) {
			let R = 6371e3;
			let φ1 = this.toRadians(lat1);
			let φ2 = this.toRadians(lat2);
			let Δφ = this.toRadians(lat2 - lat1);
			let Δλ = this.toRadians(lon2 - lon1);
			let a = Math.sin(Δφ / 2) * Math.sin(Δφ / 2) + Math.cos(φ1) * Math.cos(φ2) * Math.sin(Δλ / 2) * Math.sin(Δλ / 2);
			let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
			let d = R * c;
			let km = Math.round(d / 1000);
			let miles = Math.round(km / 1.609344);
			return `apx. ${km} km  / ${miles} miles from your location`;
		},
		getDirectionCode: function (deg) {
			for (let i = 0; i < this.windDirections.length; i++) {
				if (i === 0 && deg >= this.windDirections[i].min) {
					return this.windCodes[i];
				} else if (deg >= this.windDirections[i].min && deg < this.windDirections[i].max) {
					return this.windCodes[i];
				}
			}
			return '';
		},
		fetchWeather: function (tempData) {
			return new Promise((resolve, reject) => {
				let count = 0;
				tempData.forEach((item) => {
					item.weather = {
						main: {
							humidity: '',
							pressure: '',
							temp: '',
							temp_max: '',
							temp_min: ''
						},
						sys: {
							sunrise: '',
							sunset: ''
						},
						weather: '',
						wind: {
							deg: '',
							speed: ''
						}
					};
					this.axios.get(`https://us-central1-safe-3ee55.cloudfunctions.net/weather?lat=${item.coordinates[0][1]}&lon=${item.coordinates[0][0]}`).then((result) => {
						item.windCode = this.getDirectionCode(result.data.wind.deg);
						item.weather = {
							main: {
								humidity: result.data.main.humidity,
								pressure: result.data.main.pressure,
								temp: result.data.main.temp,
								temp_max: result.data.main.temp_max,
								temp_min: result.data.main.temp_min
							},
							sys: {
								sunrise: result.data.sys.sunrise,
								sunset: result.data.sys.sunset
							},
							weather: result.data.weather,
							wind: {
								deg: result.data.wind.deg,
								speed: result.data.wind.speed
							}
						};
						count++;
						if (count === tempData.length) {
							resolve(tempData);
						}
					}).catch((reason) => {
						console.log(reason);
					});
				});
			});
		},
		checkRoute: function () {
			if (this.origin.length && this.destination.length) {
				this.loadingRoute = true;
				let parser = new DOMParser();
				this.axios.get(`https://us-central1-safe-3ee55.cloudfunctions.net/directions?origin=${this.origin}&destination=${this.destination}`).then((result) => {
					this.temp = result.data;
					let steps = result.data.routes[0].legs[0].steps;
					let lat = result.data.routes[0].legs[0].start_location.lat;
					let lon = result.data.routes[0].legs[0].start_location.lng;
					this.mapsUrl = `https://www.google.com/maps/dir/${result.data.routes[0].legs[0].start_address}/${result.data.routes[0].legs[0].end_address}`;
					let data = [];
					steps.forEach((step) => {
						let tags = parser.parseFromString(step.html_instructions, 'text/html').getElementsByTagName('b');
						for (let key in tags) {
							let filterArr = ['east', 'west', 'north', 'south', 'northeast', 'northwest', 'southeast', 'southwest', '1st', '2nd', '3rd', '4th', '5th', 'right', 'left'];
							if (tags.hasOwnProperty(key)) {
								if (!filterArr.includes(tags[key].innerText)) {
									if (!data.includes(tags[key].innerText)) {
										data.push(tags[key].innerText);
									}
								}
							}
						}
					});
					let locationMatches = {};
					this.displayData = [];
					let tempData = [];
					for (let key in this.conditions) {
						if (this.conditions.hasOwnProperty(key)) {
							data.forEach((location) => {
								this.conditions[key].forEach((condition) => {
									if (condition && condition.name && condition.name.includes(location)) {
										if (condition.condition !== 'Easily passable') {
											if (!locationMatches[location]) {
												locationMatches[location] = [condition.condition];
												let mapsUrls = [];
												condition.coordinates.forEach((coordinate) => {
													mapsUrls.push(`https://www.google.com/maps/place/${coordinate[1]},${coordinate[0]}`);
												});
												tempData.push({
													lat: lat,
													lon: lon,
													openDrop: false,
													mapsUrls: mapsUrls,
													mapsUrl: `https://www.google.com/maps/place/${condition.coordinates[0][1]},${condition.coordinates[0][0]}`,
													condition: condition.condition,
													location: location,
													longLocation: condition.name,
													coordinates: condition.coordinates
												});
											} else if (!locationMatches[location].includes(condition.condition)) {
												locationMatches[location].push(condition.condition);
												for (let i = 0; i < tempData.length; i++) {
													if (tempData[i].location === location) {
														tempData[i].condition = `${tempData[i].condition}, ${condition.condition}`;
														break;
													}
												}
											}
										}
									}
								});
							});
						}
					}
					if (tempData.length) {
						this.fetchWeather(tempData).then((result) => {
							this.displayData = result;
							this.searched = true;
							this.loadingRoute = false;
						});
					} else {
						this.searched = true;
						this.loadingRoute = false;
					}
				});
			}
		},
		getOriginData: function (addressData, placeResultData, id) {
			this.origin = `place_id:${placeResultData.place_id}`;
			this.checkRoute();
		},
		getDestinationData: function (addressData, placeResultData, id) {
			this.destination = `place_id:${placeResultData.place_id}`;
			this.checkRoute();
		}
	}
};
</script>

<style scoped lang="less" scoped>
.margin-bottom {
	margin-bottom: 50px;
}
.driving-conditions {
	.space-driving-one {
		min-height: 1px;
		&.main-mobile {
			display: none;
			margin-top: 48px;
			margin-bottom: 48px;
		}
		&.main-desktop {
			margin-top: 96px;
			margin-bottom: 96px;
		}
		@media screen and (max-width: 768px) {
			&.main-mobile {
				display: block;
			}
			&.main-desktop {
				// display: none;
			}
		}
	}
	.space-driving-two {
		min-height: 1px;
		&.main-mobile {
			display: none;
			margin-top: -48px;
			margin-bottom: 48px;
		}
		&.main-desktop {
			margin-top: 96px;
			margin-bottom: 96px;
		}
		@media screen and (max-width: 768px) {
			&.main-mobile {
				display: block;
			}
			&.main-desktop {
				// display: none;
			}
		}
	}
	// padding-bottom: 49px;
	.body-section {
		padding-top: 140px;
		@media screen and (max-width: 768px) {
			padding-top: 88px;
		}
		.up-to-date {
			margin-top: 97px;
			margin-bottom: 124px;
			.para {
				text-align: left;
				max-width: 894px;
			}
			.title {
				text-align: left;
				max-width: 826px;
			}
			.dashes {
				margin-bottom: 47px;
			}
			.points {
				margin-left: 46px;
				@media screen and (max-width: 1220px) {
					margin-left: 0px;
				}
				.point {
					display: grid;
					grid-template-columns: 53px auto;
					@media screen and (max-width: 1220px) {
						grid-template-columns: 24px auto;
					}
					grid-column-gap: 16px;
					margin-bottom: 42px;
					margin-top: 48px;
					.left {
						position: relative;
						img {
							width: 100%;
							position: absolute;
							left: 0px;
							top: 19px;
						}
					}
					.right {
						text-align: left;
						max-width: 549px;
						font-family: Montserrat;
						font-size: 18px;
						font-weight: normal;
						font-style: normal;
						font-stretch: normal;
						line-height: 1.62;
						letter-spacing: 1.8px;
						color: #000000;
						@media screen and (max-width: 1220px) {
							font-size: 14px;
						}
					}
				}
			}
		}
		.selected-month {
			margin-top: 97px;
			display: grid;
			grid-template-columns: 1fr 2fr;
			grid-column-gap: 40px;
			@media screen and (max-width: 1220px) {
				grid-template-columns: 1fr;
			}
			.point-section {
				.point {
					display: grid;
					grid-template-columns: 53px auto;
					@media screen and (max-width: 1220px) {
						grid-template-columns: 24px auto;
					}
					grid-column-gap: 16px;
					margin-bottom: 21px;
					.left {
						position: relative;
						img {
							width: 100%;
							position: absolute;
							top: 19px;
						}
					}
					.right {
						max-width: 549px;
						font-family: Montserrat;
						font-size: 18px;
						@media screen and (max-width: 1220px) {
							font-size: 14px;
						}
						font-weight: normal;
						font-style: normal;
						font-stretch: normal;
						line-height: 1.62;
						letter-spacing: 1.8px;
						color: #000000;
					}
				}
				.point {
					text-align: left;
				}
			}
			.left {
				@media screen and (max-width: 1220px) {
					margin-bottom: 70px;
				}
				.big-pic {
					width: 100%;
					object-fit: cover;
				}
			}
			.right {
				.mini-title {
					padding-bottom: 24px;
					text-align: left;
					height: 56px;
					font-size: 34px;
					font-weight: 600;
					font-style: normal;
					font-stretch: normal;
					line-height: 1.35;
					letter-spacing: 1.7px;
					color: #00476e;
					@media screen and (max-width: 1220px) {
						max-width: 329px;
						height: 26px;
						font-family: Montserrat;
						font-size: 20px;
						font-weight: 600;
						font-style: normal;
						font-stretch: normal;
						line-height: 1.35;
						letter-spacing: 1.7px;
						color: #00476e;
					}
				}
			}
		}
		.searcher {
			height: 48px;
			position: relative;
			.info {
				cursor: pointer;
				user-select: none;
				width: 16px;
				height: 16px;
				border-radius: 50%;
				border: solid 1px #4d4d4d;
				position: absolute;
				left: 648px;
				line-height: 16px;
				font-size: 12px;
				@media screen and (max-width: 703px) {
					left: 317px;
				}
				@media screen and (max-width: 468px) {
					right: 24px;
					left: initial;
				}
				.info-box {
					opacity: 0;
					transition: .3s;
					&.active {
						opacity: 1;
					}
					pointer-events: none;
					top: 30px;
					left: 30px;
					background-color: white;
					z-index: 200;
					position: absolute;
					width: 300px;
					border: 1px solid black;
					padding-left: 10px;
					padding-right: 10px;
					padding-top: 14px;
					padding-bottom: 14px;
					box-shadow: 0px 3px 5px 1px rgba(0,0,0,0.07);
					@media screen and (max-width: 1220px) {
						right: -24px;
						left: initial;
						max-width: calc(~'100vw - 72px');
					}
				}
			}
			input {
				float: left;
				width: 304px;
				height: 48px;
				border: solid 1px #f9db49;
				padding-left: 16px;
				box-sizing: border-box;
				font-size: 15px;
				font-weight: normal;
				font-style: normal;
				font-stretch: normal;
				line-height: 48px;
				margin-right: 24px;
				letter-spacing: 0.6px;
				color: #4d4d4d;
				@media screen and (max-width: 703px) {
					margin-bottom: 14px;
				}
				@media screen and (max-width: 468px) {
					width: 80%
				}
			}
		}
		.weather-and-conditions {
			margin-top: 120px;
			@media screen and (max-width: 768px) {
				margin-top: 155px;
			}
			.bottom {
				position: relative;
				margin-top: 87px;
				@media screen and (max-width: 768px) {
					margin-top: 66px;
				}
				.explanation {
					padding-top: 30px;
					padding-bottom: 38px;
					padding-left: 16px;
					padding-right: 16px;
					background-color: #fafafa;
					display: grid;
					grid-template-columns: 1fr 1fr 1fr 1fr;
					@media screen and (max-width: 1220px) {
						grid-template-columns: 1fr 1fr 1fr;
					}
					@media screen and (max-width: 880px) {
						grid-template-columns: 1fr 1fr;
					}
					@media screen and (max-width: 568px) {
						grid-template-columns: 1fr;
					}

					grid-column-gap: 12px;
					grid-row-gap: 21px;
					.exp {
						display: grid;
						grid-template-columns: 43px auto;
						grid-column-gap: 9px;
						position: relative;
						.text {
							text-align: left;
							font-family: Montserrat;
							font-size: 16px;
							font-weight: normal;
							font-style: normal;
							font-stretch: normal;
							line-height: 35px;
							letter-spacing: 1.3px;
							color: #000000;
						}
						img {
							position: relative;
							top: 50%;
							transform: translate3d(0px, -50%, 0px);
							max-width: 100%;
							max-height: 16px;
						}
					}
				}
				img {
					margin-left: auto;
					margin-right: auto;
					width: 100%;
					object-fit: contain;
				}
			}
			.top {
				display: grid;
				grid-template-columns: 1fr 1fr;
				@media screen and (max-width: 768px) {
					grid-template-columns: 1fr;
				}
				.right {
					position: relative;
					display: grid;
					grid-template-columns: 1fr;
					@media screen and (max-width: 1220px) {
            top: -66px;
					}
					@media screen and (max-width: 768px) {
						transform: translate3d(0px, 0px, 0px);
					}
					.drop {
						z-index: 30;
						position: absolute;
						top: 264px;
						right: 0px;
						background-color: white;
						outline: solid 1px #f9db49;
						padding-bottom: 0px;
						padding-left: 32px;
						width: 304px;
						height: 48px;
						transition: .3s;
            transition-delay: .3s;
            @media screen and (max-width: 1220px) {
              top: 340px;
            }
						@media screen and (max-width: 768px) {
							right: initial;
							left: 0px;
							position: relative;
							top: 0px;
							margin-top: 69px;
							max-width: calc(~'100vw - 81px');
						}
						.heading {
							display: grid;
							grid-template-columns: 1fr 1fr;
							.caret {
								position: absolute;
								top: 16px;
								left: 100%;
								transition: .3s;
								transform: translate3d(-100%, 0px, 0px) scaleY(1);
								padding-right: 24px;
							}
							.text {
								text-align: left;
								width: 232.3px;
								height: 48px;
								line-height: 48px;
								font-size: 15px;
								font-weight: 600;
								font-style: normal;
								font-stretch: normal;
								letter-spacing: 0.6px;
								color: #4d4d4d;
							}
						}
						&.active {
							.heading {
								.caret {
									transform: translate3d(-100%, 0px, 0px) scaleY(-1);
								}
							}
							transition-delay: 0s;
							width: 552px;
							height: 231px;
							@media screen and (max-width: 768px) {
								max-width: calc(~'100vw - 81px');
								height: 330px;
							}
						}
						.content {
							position: relative;
							z-index: 30;
							display: grid;
							grid-template-columns: 1fr 1fr;
							opacity: 0;
							pointer-events: none;
							transition: .3s;
							transition-delay: 0s;
							@media screen and (max-width: 768px) {
								grid-template-columns: 1fr;
							}
							&.active {
								transition-delay: .3s;
								opacity: 1;
								pointer-events: initial;
							}
							.item {
								display: grid;
								grid-template-columns: auto auto;
								grid-column-gap: 16px;
								margin-bottom: 15px;
								.checkmark {
									color: white;
									width: 16px;
									height: 16px;
									line-height: 16px;
									outline: solid 1px #f9db49;
									transition: .3s;
									transform: rotate3d(1, 1, 0, 180deg);
								}
								&.selected {
									.checkmark {
										transform: rotate3d(1, 1, 0, 0deg);
										background-color: #f9db49;
									}
								}
								.text {
									text-align: left;
									max-width: 232px;
									@media screen and (max-width: 1220px) {
										max-width: initial;
									}
									height: 16px;
									font-size: 15px;
									font-weight: normal;
									font-style: normal;
									font-stretch: normal;
									line-height: normal;
									letter-spacing: 0.6px;
									color: #4d4d4d;
									&.selected {
										opacity: .4;
										pointer-events: none;
									}
								}
							}
						}
					}
					.map {
						top: 40px;
						left: 100%;
						transform: translate3d(calc(~'-200% - 10px'), 0px, 0px);
						position: absolute;
						display: grid;
						grid-template-columns: auto 69px auto;
						.img-cont-top {
							position: relative;
							height: 69px;
							img {
								cursor: pointer;
								&.active {
									opacity: 0;
								}
								transition: .3s;
								&.one {
									right: -4px;
									width: 74.1px;
								}
								&.two {
									left: 0px;
									width: 100%;
								}
								&.three {
									width: 84px;
									left: -3px;
								}
								position: absolute;
								bottom: 0px;
							}
						}
						.img-cont-mid {
							position: relative;
							height: 35px;
							img {
								cursor: pointer;
								&.active {
									opacity: 0;
								}
								transition: .3s;
								&.four {
									right: -3px;
									width: 72px;
								}
								&.five {
									left: 0px;
									width: 100%;
								}
								&.six {
									width: 87px;
									left: -3px;
								}
								position: absolute;
								bottom: 0px;
							}
						}
						.img-cont-bot {
							position: relative;
							height: 54px;
							img {
								cursor: pointer;
								&.active {
									opacity: 0;
								}
								transition: .3s;
								&.seven {
									right: -4px;
									width: 43px;
								}
								&.eight {
									left: -3px;
									width: 73px;
								}
								&.nine {
									width: 65px;
									left: -2px;
								}
								position: absolute;
								top: -3px;
							}
						}
					}
				}
				.left {
					text-align: left;
					.title {
						max-width: 446px;
					}
					.para {
						max-width: 465px;
					}
					.dashes {
						margin-bottom: 47px;
					}
				}
			}
		}

		.bluecarrental-banner {
			margin-top: 97px;
			margin-bottom: 124px;
		}
		.conditions-by-month {
			text-align: left;
			.month-grid {
				margin-top: 47px;
				margin-left: 139px;
				display: grid;
				grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
				grid-row-gap: 48px;
				grid-column-gap: 34px;
				@media screen and (max-width: 1220px) {
					grid-template-columns: 1fr 1fr 1fr 1fr;
					grid-row-gap: 48px;
					margin-left: 0px;
				}
				.month {
					transition: .3s;
					font-size: 13px;
					letter-spacing: 1.3px;
					text-align: center;
					height: 32px;
					line-height: 32px;
					border-radius: 32px;
					background-color: #FFFFFF;
					box-shadow: 0px 3px 5px 1px rgba(0,0,0,0.07);
					@media screen and (min-width: 1221px) {
						height: 48px;
						width: 96px;
						border-radius: 24px;
						box-shadow: 2px 2px 7px 2px rgba(0,0,0,0.15);
						font-size: 18px;
						letter-spacing: 2.1px;
						line-height: 48px;
					}
					&.active {
						font-weight: bold;
						background-color: #00476E;
						color: white;
					}
				}
			}
			.para {
				max-width: 948px;
			}
			.dashes {
				margin-bottom: 47px;
			}
		}
		.results {
			background-color: #fafafa;
			margin-top: 48px;
			@media screen and (max-width: 703px) {
				margin-top: 118px;
			}
			.loader {
				position: relative;
				padding-top: 48px;
				padding-bottom: 48px;
				display: grid;
				grid-template-columns: 48px 100px 48px;
				margin-left: auto;
				margin-right: auto;
				width: 196px;
				.left {
					position: relative;
					right: 0px;
					top: 3px;
					height: 14px;
					width: 48px;
					animation: 3s flip infinite;
				}
				.text {
					text-align: center;
				}
				.right {
					position: relative;
					left: 0px;
					top: 3px;
					height: 14px;
					width: 48px;
					animation: 3s flip infinite;
				}
			}
			.top {
				display: grid;
				grid-template-columns: 1fr auto;
				padding-right: 16px;
			}
			.bottom {
				padding-right: 16px;
				padding-top: 24px;
				display: grid;
				grid-template-columns: 3fr 1fr 1fr;
				@media screen and (max-width: 1220px) {
					grid-template-columns: initial;
					grid-template-areas:
					'location'
					'temp'
					'wind';
				}
			}
			.date {
				padding-left: 130px;
				@media screen and (max-width: 768px) {
					padding-left: 24px;
				}

				box-sizing: border-box;
				text-align: left;
				padding-top: 48px;
				width: 100%;
				height: 99px;
				font-size: 11px;
				font-weight: normal;
				font-style: normal;
				font-stretch: normal;
				line-height: 1.91;
				letter-spacing: 0.9px;
				color: #4d4d4d;
			}
			.info {
				margin-left: 130px;
				margin-right: 130px;
				@media screen and (max-width: 768px) {
					margin-left: auto;
					margin-right: auto;
				}
				padding-bottom: 48px;
				&.dashed {
					background-image: linear-gradient(to right, #cccccc 66%, rgba(255,255,255,0) 0%);
					background-position: bottom;
					background-size: 26px 2px;
					background-repeat: repeat-x;
					margin-bottom: 46px;
				}
				text-align: left;
				&.coast-clear {
					text-align: center;
					@media screen and (max-width: 768px) {
						margin-left: auto;
						margin-right: auto;
					}
				}
				&.show-route {
					text-align: center;
					position: relative;
					height: 57px;
					@media screen and (max-width: 768px) {
						margin-left: auto;
						margin-right: auto;
					}
					a {
						margin-left: auto;
						margin-right: auto;
						color: #00476E;
						font-size: 15px;
						letter-spacing: 0.6px;
						line-height: 57px;
					}
				}
				.see-on-map {
					display: grid;
					grid-template-columns: 1fr;
					grid-row-gap: 12px;
					margin-bottom: 20px;
					position: relative;
					@media screen and (max-width: 1220px) {
						margin-bottom: 0px;
					}
					.link-text {
						height: 21px;
						font-family: Montserrat;
						font-size: 13px;
						font-weight: normal;
						font-style: normal;
						font-stretch: normal;
						line-height: 1.38;
						letter-spacing: 1.3px;
						text-align: right;
						color: #00476e;
						@media screen and (max-width: 1220px) {
							text-align: left;
							padding-left: 24px;
							margin-top: 35px;
						}
						span {
							img {
								transform: translate3d(0px, 3px, 0px);
							}
						}
					}
					.links {
						z-index: 2;
						display: grid;
						grid-template-columns: 1fr;
						position: absolute;
						top: 30px;
						@media screen and (max-width: 1220px) {
							top: 60px;
						}
						.url {
							transition: .3s;
							background-color: white;
							opacity: 0;
							display: none;
							height: 21px;
							font-family: Montserrat;
							font-size: 13px;
							font-weight: normal;
							font-style: normal;
							font-stretch: normal;
							line-height: 1.38;
							letter-spacing: 1.3px;
							text-align: right;
							color: #00476e;
							padding-left: 14px;
							padding-right: 14px;
							border: solid 1px #cccccc;
							width: 140px;
							margin-bottom: 3px;
							text-align: center;
							line-height: 21px;
							&:hover {
								font-weight: bold;
								border: solid 1px #00476e;
								background-color: #00476e;
								color: white;
							}
						}
					}
					&.open {
						.url {
							opacity: 1;
							display: initial;
						}
					}
				}
				.location {
					display: grid;
					grid-template-columns: 16px auto;
					grid-column-gap: 12px;
					position: relative;
					.circle {
						position: relative;
						top: 50%;
						transform: translate3d(0px, -50%, 0px);
						height: 16px;
						width: 16px;
						background-color: #95F9E3;
						border-radius: 50%;
					}
					.text {
						font-size: 18px;
						letter-spacing: 1.17px;
						line-height: 21px;
						font-weight: bold;
					}
				}
				.temp {
					margin-left: auto;
					margin-right: auto;
					@media screen and (max-width: 1220px) {
						grid-area: temp;
						margin-left: initial;
						margin-right: initial;
					}
				}
				.distance {
					@media screen and (max-width: 1220px) {
						grid-area: location;
					}
				}
				.temp, .wind, .distance {
					margin-top: 10px;
					display: grid;
					grid-template-columns: 7px 24px auto;
					grid-column-gap: 12px;
					position: relative;
					.icon {
						width: 24px;
						position: relative;
						top: 50%;
						transform: translate3d(0px, -50%, 0px);
					}
					.text {
						font-size: 14px;
						font-weight: normal;
						font-style: normal;
						font-stretch: normal;
						line-height: 1.5;
						letter-spacing: 1.2px;
						color: #000000;
					}
				}
				position: relative;
				.wind {
					display: flex;
					justify-content: flex-end;
					@media screen and (max-width: 1220px) {
						grid-area: wind;
						justify-content: initial;
					}
					.icon {
						top: 0px;
						height: 24px;
						right: 26px;
						transform: translate3d(50px, 0px, 0px);
						@media screen and (max-width: 1220px) {
							transform: translate3d(0px, 0px, 0px);
							left: 18px;
						}
					}
					.text {
						padding-left: 30px;
					}
				}
				.condition {
					text-align: left;
					padding-left: 8px;
					display: grid;
					grid-template-columns: 7px auto;
					grid-column-gap: 12px;
					padding-top: 14px;
					.text {
						font-size: 16px;
						letter-spacing: 1.17px;
						line-height: 21px;
					}
				}
			}
		}
		.check-my-route {
			.title {
				text-align: left;
				max-width: 800px;
			}
			.dashes {
				margin-bottom: 47px;
			}
			.para {
				text-align: left;
				max-width: 948px;
				margin-bottom: 64px;
			}
		}
	}
	.header {
		height: 628px;
		max-height: 628px;
		background-image: url('~@/assets/jpg/badroadconditions_header.jpg');
		background-size: cover;
		background-position: 50% 100%;
		.header-image {
			display: none;
		}
		@media screen and (max-width: 1220px) {
			background-image: none;
			height: initial;
			max-height: initial;
			.header-image {
				margin-top: 48px;
				display: block;
				width: 100%;
				max-height: 440px;
				object-fit: cover;
				object-position: bottom;
			}
		}
		.content {
			position: relative;
			top: 0px;
			height: 100%;
			left: 0px;
			text-align: left;
			.title {
				padding-top: 68px;
				@media screen and (max-width: 768px) {
					padding-top: 40px;
				}
				position: relative;
				max-width: 374px;
				font-size: 48px;
				font-weight: bold;
				letter-spacing: 1.92px;
				line-height: 72px;
			}
			.para {
				padding-top: 38px;
				max-width: 374px;
				font-size: 18px;
				letter-spacing: 1.75px;
				line-height: 34px;
			}
			.button {
				display: grid;
				grid-template-columns: 80% 20%;
				height: 48px;
				width: 260px;
				background-color: #FFFFFF;
				box-shadow: 0px 3px 5px 1px rgba(0,0,0,0.07);
				border-left: 4px solid #AF7595;
				margin-top: 58px;
				.text {
					color: black;
					height: 30px;
					width: 161px;
					font-size: 18px;
					font-weight: 600;
					letter-spacing: 1.5px;
					line-height: 48px;
					padding-left: 32px;
				}
				.arrow {
					position: relative;
					top: 50%;
					transform: translate3d(0px, -50%, 0px);
				}
			}
		}
	}
	.dashes {
		margin-top: 38px;
		display: grid;
		grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
		width: 60px;
		.dash {
			width: 8px;
			height: 2px;
			background-color: #F9DB49;
		}
	}
}
@keyframes flip {
	0% {
		transform: rotate3d(1, 0, 0, 90deg);
		background-color: #F9DB49;
	}
	25% {
		transform: rotate3d(1, 0, 0, 0deg);
	}
	49% {
		background-color: #F9DB49;
	}
	50% {
		background-color: #00486c;
		transform: rotate3d(1, 0, 0, 90deg);
	}
	75% {
		transform: rotate3d(1, 0, 0, 0deg);
	}
	99% {
		background-color: #00486c;
	}
	100% {
		background-color: #F9DB49;
		transform: rotate3d(1, 0, 0, 90deg);
	}
}
</style>
